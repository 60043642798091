<template>
	<Card>
		<!-- <template #title>
			{{title}}
		</template> -->
		<template #content>
			<div style="color: #444; margin:0;font: normal 14px/20px Arial, Helvetica, sans-serif; height:100%;">
				<div style="height:auto; min-height:100%; ">
					<div style="text-align: center; width:800px; margin-left: -400px; position:absolute; top: 30%; left:50%; display: contents;">
						<h1 style="margin:0; font-size:150px; line-height:150px; font-weight:bold;">403</h1>
						<h2 style="margin-top:20px;font-size: 30px;">Forbidden</h2>
						<p>You don't have permission to access this page!</p>
					</div>
				</div>
			</div>
		</template>
	</Card>
</template>

<script>
export default {
	data() {
		return {
			title: '403 Forbidden',
		}
	},
	methods: {
		
	},
	created() {
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		
	},
}
</script>